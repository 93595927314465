import React, { useState, useEffect } from 'react';
import '../Stylesheets/About.css'
import TypeEffect from './TypeEffect';
import SingularTypeEffect from './SingularTypeEffect';
import OrbitingElement from './OrbitingElement';
import {abt} from './AboutText.js'
import AboutCircle from './AboutCircles.js';


export default function About() {
    const texts = ["Design", "Develop", "Deploy"];
    const [count, setCount] = useState(0);
    const [index, setIndex] = useState(0);
    const [letter, setLetter] = useState('');
    const [isPausing, setIsPausing] = useState(false);

    useEffect(() => {
        if (isPausing) {
            const pauseTimer = setTimeout(() => {
                setIsPausing(false);
                setIndex(index + 1);
            }, 1000); // 1-2 second pause
            return () => clearTimeout(pauseTimer);
        }

        const type = () => {
            if (count >= texts.length) {
                setCount(0);
                return;
            }

            const currentText = texts[count];

            if (index <= currentText.length) {
                // Typing phase
                setLetter(currentText.slice(0, index));
                setIndex(index + 1);
            } else if (index > currentText.length && index <= currentText.length * 2) {
                // Deleting phase
                setLetter(currentText.slice(0, currentText.length * 2 - index));
                setIndex(index + 1);
            }

            if (index === currentText.length) {
                // Start pause after typing phase
                setIsPausing(true);
            } else if (index === currentText.length * 2) {
                // Reset for the next word
                setCount((prevCount) => (prevCount + 1) % texts.length);
                setIndex(0);
            }
        };

        if (!isPausing) {
            const timerId = setTimeout(type, 150);
            return () => clearTimeout(timerId);
        }
    }, [index, count, texts, isPausing]);

    return (
        <div id="about" className='AboutContainer'>
            <div className='AboutTitle'>
                 
            </div>
    
                

            <div className='WhyChooseUs'>
               
       

            <AboutCircle />
            </div>
            <div className='Conclusion'>
            Embrace the Future with Alpha Vision

Let's redefine the digital landscape together. Contact us to bring your ideas to life with the power of AI and innovative web development.


            </div>
 
        </div>
    );
}
