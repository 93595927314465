export const aboutText = {
  EN: [
      { name: 'Future-Ready Solutions', description: 'With a finger on the pulse of technology, we ensure your website is not just for today but ready for tomorrow.' },
      { name: 'Expert Team with a Vision', description: 'Our team is a blend of tech-savvy developers, creative designers, AI specialists, and digital strategists, all driven by a passion for innovation.' },
      { name: 'Tailored to Perfection', description: 'At Alpha Vision, customization is key. We tailor every solution to fit your unique needs and aspirations.' },
      { name: 'Client-Centric and Transparent', description: 'Your vision guides our mission. We believe in open communication, ensuring your ideas are turned into a digital reality that exceeds expectations.' },
      { name: 'Innovative Design Thinking', description: 'We approach problems with a design mindset, ensuring creative solutions that are both effective and aesthetically pleasing.' },
      { name: 'State-of-the-Art Technology', description: 'We leverage cutting-edge technology to ensure your project stands out in the digital landscape.' },
  ],
  FR: [
      { name: 'Solutions Prêtes pour le Futur', description: 'Avec un doigt sur le pouls de la technologie, nous assurons que votre site Web ne soit pas seulement pour aujourd’hui mais prêt pour demain.' },
      { name: 'Équipe d’Experts avec une Vision', description: 'Notre équipe est un mélange de développeurs avertis, de designers créatifs, de spécialistes de l’IA et de stratèges numériques, tous animés par une passion pour l’innovation.' },
      { name: 'Sur Mesure à la Perfection', description: 'Chez Alpha Vision, la personnalisation est la clé. Nous adaptons chaque solution pour répondre à vos besoins et aspirations uniques.' },
      { name: 'Centré sur le Client et Transparent', description: 'Votre vision guide notre mission. Nous croyons en une communication ouverte, en veillant à ce que vos idées se transforment en une réalité numérique qui dépasse les attentes.' },
      { name: 'Pensée de Conception Innovante', description: 'Nous abordons les problèmes avec un état d\'esprit de conception, assurant des solutions créatives à la fois efficaces et esthétiquement agréables.' },
      { name: 'Technologie de Pointe', description: 'Nous exploitons la technologie de pointe pour garantir que votre projet se démarque dans le paysage numérique.' },
  ],
  ES: [
      { name: 'Soluciones Preparadas para el Futuro', description: 'Con un dedo en el pulso de la tecnología, aseguramos que tu sitio web no sea solo para hoy sino que esté preparado para el mañana.' },
      { name: 'Equipo Experto con una Visión', description: 'Nuestro equipo es una mezcla de desarrolladores expertos en tecnología, diseñadores creativos, especialistas en IA y estrategas digitales, todos impulsados por una pasión por la innovación.' },
      { name: 'Adaptado a la Perfección', description: 'En Alpha Vision, la personalización es clave. Adaptamos cada solución para ajustarse a tus necesidades y aspiraciones únicas.' },
      { name: 'Centrado en el Cliente y Transparente', description: 'Tu visión guía nuestra misión. Creemos en la comunicación abierta, asegurándonos de que tus ideas se conviertan en una realidad digital que supere las expectativas.' },
      { name: 'Pensamiento de Diseño Innovador', description: 'Enfrentamos los problemas con una mentalidad de diseño, asegurando soluciones creativas que son efectivas y estéticamente agradables.' },
      { name: 'Tecnología de Vanguardia', description: 'Utilizamos tecnología de vanguardia para asegurar que tu proyecto destaque en el paisaje digital.' },
  ],
  RU: [
      { name: 'Услуги Готовые к Будущему', description: 'Держа руку на пульсе технологий, мы гарантируем, что ваш веб-сайт актуален не только сегодня, но и готов к завтрашнему дню.' },
      { name: 'Команда Экспертов с Видением', description: 'Наша команда - это сочетание технологически подкованных разработчиков, творческих дизайнеров, специалистов по ИИ и цифровых стратегов, всех объединяет страсть к инновациям.' },
      { name: 'Индивидуальный Подход к Совершенству', description: 'В Alpha Vision ключевым является индивидуальный подход. Мы адаптируем каждое решение, чтобы оно соответствовало вашим уникальным потребностям и стремлениям.' },
      { name: 'Ориентация на Клиента и Прозрачность', description: 'Ваше видение определяет нашу миссию. Мы верим в открытое общение, чтобы ваши идеи превратились в цифровую реальность, превосходящую ожидания.' },
      { name: 'Инновационное Дизайн-Мышление', description: 'Мы подходим к проблемам с дизайнерским мышлением, обеспечивая творческие решения, которые эффективны и эстетически привлекательны.' },
      { name: 'Передовые Технологии', description: 'Мы используем передовые технологии, чтобы ваш проект выделялся в цифровом ландшафте.' },
  ]
};
