import { useEffect, useRef, useState } from 'react';
import '../Stylesheets/Services.css';
import { servicetext } from './ServiceText.js';
import SingularTypeEffect from './SingularTypeEffect.js';
import { useLanguage } from './LanguageContext';

export default function Services() {
    // Initialize an array with a false value for each service item
    const [serv, setServ] = useState(servicetext.EN)
    const itemRefs = useRef(new Array(serv.length).fill(null));
  
        const [isVisible, setIsVisible] = useState(new Array(serv.length).fill(false));
    const { language, switchLanguage } = useLanguage();

    useEffect(()=>{
        switch (language) {
            case 'EN':
                setServ(servicetext.EN);
                break;
            case 'FR':
                setServ(servicetext.FR);
                break;
            case 'ES':
                setServ(servicetext.ES);
                break;
            case 'RU':
                setServ(servicetext.RU);
                break;
        
        }

    },[language])

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries, observer) => {
                entries.forEach((entry, index) => {
                    if (entry.isIntersecting) {
                        // Find the index of the item using the ref array
                        const itemIndex = itemRefs.current.findIndex(ref => ref === entry.target);
                        // Update visibility state for the item
                        setIsVisible(prev => prev.map((visible, index) => index === itemIndex ? true : visible));
                        observer.unobserve(entry.target);
                    }
                });
            },
            { threshold: 0.2 }
        );

        // Observe each service item
        itemRefs.current.forEach((ref, index) => {
            if (ref) {
                observer.observe(ref);
            }
        });

        return () => {
            observer.disconnect();
        };
    }, [itemRefs]);

    return (
        <div id="services" className='OuterServicesContainer'>
 
            <div className='ServicesContainer'>
                {serv.map((s, index) => {
                    const positionClass = index % 2 === 0 ? 'Right' : 'Left';
                    const visibilityClass = isVisible[index] ? 'visible' : '';
                    return (
                        <div 
                            className={`Service ${positionClass} ${visibilityClass}`} 
                            key={s.id} 
                            ref={el => itemRefs.current[index] = el} // Set the ref for each item
                        >
                            <p className='Title'>{s.name}</p>
                            <p className='Description'>{s.description}</p>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}
