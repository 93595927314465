import '../Stylesheets/Footer.css'
import { useState, useEffect } from 'react';
import { FaInstagram, FaFacebookSquare } from 'react-icons/fa';
import { Link } from 'react-scroll'
import { useLanguage } from './LanguageContext';


export default function Footer() {
 
const { language, switchLanguage } = useLanguage();
const [home, setHome] = useState('HOME')
const [about, setAbout] = useState('ABOUT')
const [services, setServices] = useState('SERVICES')
const [contact, setContact] = useState('CONTACT')

useEffect(() => {
  switch (language) {
      case 'EN':
          setHome('HOME');
          setAbout('ABOUT')
          setServices('SERVICES')
          setContact('CONTACT')
          break;
      case 'FR':
          setHome('ACCUEIL');
          setAbout('À PROPOS')
          setServices('SERVICES')
          setContact('CONTACT')
          break;
      case 'ES':
          setHome('INICIO');
          setAbout('SOBRE NOSOTROS')
          setServices('SERVICIOS')
          setContact('CONTACT')
          break;
      case 'RU':
          setHome('ДОМ');
          setAbout('УСЛУГИ')
          setServices("О НАС")
          setContact('КОНТАКТ')
          break;
     
  }
}, [language])

 return(
    <div className='footer'>
    <div className='container'>
        <ul>
            <li className='item'>
            <Link to='hero' syp={true} smooth={true}   duration={500}  > {home}  </Link>
            </li>
            <li className='item'>
            <Link to='about' syp={true} smooth={true}   duration={500}  >{about}  </Link>
            </li>
            <li className='item'>
            <Link to='menu' syp={true} smooth={true}   duration={500}  > {services}  </Link>
            </li>
            <li className='item'>
            <Link to='menu' syp={true} smooth={true}   duration={500}  > {contact}  </Link>
            </li>

                    <div className='SocialMedia'>
            <a href="https://www.instagram.com/yourusername" target="_blank" rel="noopener noreferrer">
        <FaInstagram size={30} /> {/* You can set the size as needed */}
      </a>
      </div>
      <div className='SocialMedia'>
      <a href="https://www.facebook.com/yourusername" target="_blank" rel="noopener noreferrer">
        <FaFacebookSquare size={30} /> {/* You can set the size as needed */}
      </a>
      </div>
        </ul>
        <div className='bottom'>
            <span className='line'></span>
            <p>2023  All rights reserved</p>
        </div>
    </div>
</div>
 )
}