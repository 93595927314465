 
import React, { useState, useEffect } from 'react'
import {  FaBars, FaTimes, FaInstagram, FaFacebookF, FaTwitter } from 'react-icons/fa'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import logo from '../Images/log.png'
import { Link } from 'react-scroll'
import '../Stylesheets/Navbar.css'
import Language from './Language';
import { useLanguage } from './LanguageContext';


export default function Navbar(){
//setting mobile nav
    const [click, setClick] = useState(false)
    const { language, switchLanguage } = useLanguage();
    const [home, setHome] = useState('HOME')
    const [about, setAbout] = useState('ABOUT')
    const [services, setServices] = useState('SERVICES')
    const [contact, setContact] = useState('CONTACT')
    

// change nav color when scrolling
const [color, setColor] = useState(true)
const handleClick = () => setClick(!click)
const changeColor = () => {
    if (window.scrollY >= 90) {
        setColor(true)
    } else {
        setColor(false)
    }
}

 
//close menu on click
const closeMenu = () => setClick(false)

useEffect(() => {
    switch (language) {
        case 'EN':
            setHome('HOME');
            setAbout('ABOUT')
            setServices('SERVICES')
            setContact('CONTACT')
            break;
        case 'FR':
            setHome('ACCUEIL');
            setAbout('À PROPOS')
            setServices('SERVICES')
            setContact('CONTACT')
            break;
        case 'ES':
            setHome('INICIO');
            setAbout('SOBRE NOSOTROS')
            setServices('SERVICIOS')
            setContact('CONTACT')
            break;
        case 'RU':
            setHome('ДОМ');
            setAbout('УСЛУГИ')
            setServices("О НАС")
            setContact('КОНТАКТ')
            break;
       
    }
}, [language])

    return (
        <div className={color ? 'header header-bg':'header'}>
            <nav className='navbar'>
                <div>
                <Link to='hero' syp={true} smooth={true} offset={50} duration={500} onClick={closeMenu}>
                    <img className='logo' src={logo} alt='logo' />
                </Link>         
                </div>
      
           <div className='Test'>
           
                <div className='hamburger' onClick={handleClick}>
                
                    {click ? (<FaTimes size={40} style={{ color: 'white' }} />)
                        : (<FaBars size={40} style={{ color: 'white' }} />)}

                </div>
                <div className='LanguageDiv'>
                <Language />
                </div>
                <ul className={click ? "nav-menu active" : "nav-menu"}>
                    <li className='nav-item'>
                    <Link to='hero' syp={true} smooth={true}   duration={500} onClick={closeMenu}> {home}  </Link>
                    </li>
                    <li className='nav-item'>
                    <Link to='about' syp={true} smooth={true}   duration={500} onClick={closeMenu}> {about}  </Link>
                    </li>
                    <li className='nav-item'>
                    <Link to='services' syp={true} smooth={true}  duration={500} onClick={closeMenu}> {services}  </Link>
                    </li>
                    <li className='nav-item'>
                    <Link to='contact' syp={true} smooth={true}   duration={500} onClick={closeMenu}> {contact}  </Link>
                    </li>
                    
                </ul>
              
            </div>
            </nav>
        </div>
    )
}
 