export const servicetext = {
    EN: [
        {
            name: 'Social Media Management',
            description: 'We establish powerful connections, foster brand loyalty, and stimulate business growth through tactical social media management. Our team is expert in developing and curating captivating content that fosters meaningful connections with your audience.'
        },
        {
            name: 'Premium Content Creation',
            description: "We create unique and captivating content, tailored to your brand and audience, to engage and inspire."
        },
        {
            name: 'Branding & Strategy',
            description: 'We develop insightful branding strategies to create a strong and consistent identity that resonates with your target audience.'
        },
        {
            name: 'Website Design & Development',
            description: 'We create aesthetic, functional, and optimized websites for an exceptional user experience and better SEO.'
        },
        {
            name: 'Search Engine Optimization',
            description: 'We enhance your website’s visibility on search engines through proven SEO strategies, thereby increasing your traffic and conversions.'
        },
        {
            name: 'Marketing Research & Consulting',
            description: 'We provide in-depth market analysis and strategic advice to guide your marketing efforts and maximize return on investment.'
        }
    ],
 FR: [
    {
        name: 'Gestion des Médias Sociaux',
        description: 'Nous établissons des connexions puissantes, favorisons la fidélité à la marque et stimulons la croissance des entreprises grâce à une gestion tactique des médias sociaux. Notre équipe est experte dans le développement et la curation de contenus captivants qui favorisent des liens significatifs avec votre public.'
    },
    {
        name: 'Création de Contenu Premium',
        description: "Nous construisons des contenus uniques et captivants, adaptés à votre marque et à votre audience, afin d'engager et d'inspirer."
    },
    {
        name: 'Branding et Stratégie',
        description: 'Nous développons des stratégies de marque perspicaces pour créer une identité forte et cohérente qui résonne avec votre public cible.'
    },
    {
        name: 'Conception et Développement de Sites Web',
        description: 'Nous créons des sites web esthétiques, fonctionnels et optimisés pour une expérience utilisateur exceptionnelle et un meilleur référencement.'
    },
    {
        name: 'Optimisation pour les Moteurs de Recherche',
        description: 'Nous améliorons la visibilité de votre site sur les moteurs de recherche grâce à des stratégies SEO éprouvées, augmentant ainsi votre trafic et vos conversions.'
    },
    {
        name: 'Recherche Marketing et Conseil',
        description: 'Nous fournissons des analyses de marché approfondies et des conseils stratégiques pour orienter vos efforts marketing et maximiser le retour sur investissement.'
    }
], RU: [
    {
        name: 'Управление Социальными Сетями',
        description: 'Мы создаем мощные связи, способствуем лояльности к бренду и стимулируем рост бизнеса через тактическое управление социальными сетями. Наша команда умело разрабатывает и курирует привлекательный контент, который способствует налаживанию значимых связей с вашей аудиторией.'
    },
    {
        name: 'Создание Премиального Контента',
        description: 'Мы создаем уникальные и захватывающие материалы, соответствующие вашему бренду и аудитории, чтобы вовлекать и вдохновлять.'
    },
    {
        name: 'Брендинг и Стратегия',
        description: 'Мы разрабатываем проницательные стратегии брендинга для создания сильной и последовательной идентичности, которая резонирует с вашей целевой аудиторией.'
    },
    {
        name: 'Дизайн и Разработка Веб-сайтов',
        description: 'Мы создаем эстетические, функциональные и оптимизированные веб-сайты для исключительного пользовательского опыта и лучшего SEO.'
    },
    {
        name: 'Поисковая Оптимизация',
        description: 'Мы повышаем видимость вашего сайта в поисковых системах с помощью проверенных стратегий SEO, тем самым увеличивая ваш трафик и конверсии.'
    },
    {
        name: 'Маркетинговые Исследования и Консалтинг',
        description: 'Мы предоставляем глубокий анализ рынка и стратегические консультации для направления ваших маркетинговых усилий и максимизации рентабельности инвестиций.'
    }] ,

    ES:[
        {
            name: 'Gestión de Redes Sociales',
            description: 'Construimos conexiones potentes, fomentamos la lealtad a la marca y estimulamos el crecimiento empresarial a través de una gestión táctica de las redes sociales. Nuestro equipo es experto en desarrollar y curar contenido atractivo que fomenta conexiones significativas con su audiencia.'
        },
        {
            name: 'Creación de Contenido Premium',
            description: 'Creamos contenidos únicos y cautivadores, adaptados a tu marca y audiencia, para involucrar e inspirar.'
        },
        {
            name: 'Branding y Estrategia',
            description: 'Desarrollamos estrategias de marca perspicaces para crear una identidad fuerte y coherente que resuene con tu público objetivo.'
        },
        {
            name: 'Diseño y Desarrollo Web',
            description: 'Creamos sitios web estéticos, funcionales y optimizados para una experiencia de usuario excepcional y un mejor SEO.'
        },
        {
            name: 'Optimización de Motores de Búsqueda',
            description: 'Mejoramos la visibilidad de tu sitio web en los motores de búsqueda mediante estrategias SEO probadas, aumentando así tu tráfico y conversiones.'
        },
        {
            name: 'Investigación de Mercado y Consultoría',
            description: 'Ofrecemos análisis de mercado profundos y asesoramiento estratégico para guiar tus esfuerzos de marketing y maximizar el retorno de la inversión.'
        }
    ]


}