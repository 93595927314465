import React, { useEffect, useRef, useState } from 'react';
import '../Stylesheets/AboutCircles.css'; // Import your CSS
import { aboutText } from './AboutText'; // Import your abt array
import { useLanguage } from './LanguageContext';

export default function AboutCircle() {
  const [isVisible, setIsVisible] = useState(false);
  const sectionRef = useRef(null);
  const { language, switchLanguage } = useLanguage();
  const [abt, setAbt] = useState(aboutText.EN)

  useEffect(()=>{
    switch (language) {
        case 'EN':
          setAbt(aboutText.EN);
            break;
        case 'FR':
          setAbt(aboutText.FR);
            break;
        case 'ES':
          setAbt(aboutText.ES);
            break;
        case 'RU':
          setAbt(aboutText.RU);
            break;
    
    }

},[language])

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        setIsVisible(true);
      }
    }, { threshold: 0.5 });

    observer.observe(sectionRef.current);

    return () => observer.disconnect();
  }, []);

  return (
    <div className="AboutCircle">
      <div className="circle-container" ref={sectionRef}>
        {abt.map((item, index) => (
          <div key={index}  className={`circle ${isVisible ? `circle-${index + 1}` : 'hidden'}`}>
            <h4>{item.name}</h4>
          
          </div>
        ))}
      </div>
    </div>
  );
}
