import '../Stylesheets/Contact.css'
import { useEffect, useRef, useState } from 'react';
import IntakeForm from "./IntakeForm";


export default function Contact() {
    const [visible,setVisible] = useState(false)
    const reference = useRef(null)
    useEffect(()=>{
        console.log(reference.current)
        const observer = new IntersectionObserver(
            (entries, observer)=>{
            if (entries[0].isIntersecting) {
                setVisible(true)
                observer.unobserve(entries[0].target)
            }
        },
        { threshold: 0.1 }

            )


            if (reference.current) {
                observer.observe(reference.current);
            }
        
            return () => {
                observer.disconnect();
            };
        }, [reference]); 
      


    
    return(
        <div id="contact" className="ContactContainer">
      
        <IntakeForm visible={visible} ref={reference} />

        </div>
    )

}