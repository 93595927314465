
import './Stylesheets/App.css';
import Hero from './Components/Hero';
import Navbar from './Components/Navbar';
import Footer from './Components/Footer';
import { LanguageProvider } from './Components/LanguageContext';
import Services from './Components/Services';
import ImageOne from './Components/ImageOne';
import Contact from './Components/Contact';
import ImageTwo from './Components/ImageTwo';
import Reviews from './Components/Reviews';
import NavbarTest from './Components/navbarTest';
import About from './Components/About';

function App() {
  return (
    <LanguageProvider>
     <Navbar />
     
      <Hero />
      <ImageTwo />
      <About />
       
      <Services />
      <Contact />
     
     
      <Footer />
      </LanguageProvider>
  );
}

export default App;
