export const IntakeFormText = {
    EN: {
        name: 'Name',
        email: 'Email',
        phoneNumber: 'Phone Number',
        companyName: 'Company Name',
        inquiryDetails: 'Inquiry Details'
    },
    FR: {
        name: 'Nom',
        email: 'E-mail',
        phoneNumber: 'Numéro de Téléphone',
        companyName: 'Nom de l’Entreprise',
        inquiryDetails: 'Détails de la Demande'
    },
    ES: {
        name: 'Nombre',
        email: 'Correo Electrónico',
        phoneNumber: 'Número de Teléfono',
        companyName: 'Nombre de la Empresa',
        inquiryDetails: 'Detalles de la Consulta'
    },
    RU: {
        name: 'Имя',
        email: 'Электронная Почта',
        phoneNumber: 'Номер Телефона',
        companyName: 'Название Компании',
        inquiryDetails: 'Детали Запроса'
    }
};


export const TitleAndSubmit = {
    EN: ["Let's Work Together", 'Submit'],
    FR: ["Travaillons Ensemble", 'Soumettre'],
    ES: ["Trabajemos Juntos", 'Enviar'],
    RU: ["Давайте работать вместе", 'Отправить']
};