import {useState, useEffect, useContext, createContext } from 'react'
const LanguageContext=createContext()
export const useLanguage = () =>useContext(LanguageContext)

export const LanguageProvider = ({ children }) => {
    const [language, setLanguage] = useState(localStorage.getItem('language') || 'EN');
    
    useEffect(() => {
        // Persist language selections to localStorage
        localStorage.setItem('language', language);
    }, [language]);

    const switchLanguage = (lang) => {
        setLanguage(lang);
        localStorage.setItem('language', lang); // Save to localStorage
        window.location.reload(); // Refresh the page
    };
    return (
        <LanguageContext.Provider value={{ language, switchLanguage }}>
            {children}
       </LanguageContext.Provider>

    )
}