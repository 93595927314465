import '../Stylesheets/IntakeForm.css'
import { useState, forwardRef, useEffect } from 'react'
import { useLanguage } from './LanguageContext';
import { IntakeFormText, TitleAndSubmit } from './IntakeFormText';
 

const IntakeForm = forwardRef(({ visible }, ref) => {
    const [formText, setFormText] = useState(IntakeFormText.EN)
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [inquiryDetails, setInquiryDetails] = useState('');
    const { language, switchLanguage } = useLanguage();
    const [headerAndButton, setHeaderAndButton] = useState(["Let's Work Together", 'Submit'])


    const handleNameChange = (e) => setName(e.target.value);
    const handleEmailChange = (e) => setEmail(e.target.value);
    const handlePhoneNumberChange = (e) => setPhoneNumber(e.target.value);
    const handleCompanyNameChange = (e) => setCompanyName(e.target.value);
    const handleInquiryDetailsChange = (e) => setInquiryDetails(e.target.value);


    const handleSubmit = (e) => {
        e.preventDefault();
        console.log({ name, email, phoneNumber, companyName, inquiryDetails });
    };

    useEffect(()=>{
        switch (language) {
            case 'EN':
                setFormText(IntakeFormText.EN);
                setHeaderAndButton(TitleAndSubmit.EN)
                break;
            case 'FR':
                setFormText(IntakeFormText.FR);
                setHeaderAndButton(TitleAndSubmit.FR)
                break;
            case 'ES':
                setFormText(IntakeFormText.ES);
                setHeaderAndButton(TitleAndSubmit.ES)
                break;
            case 'RU':
                setFormText(IntakeFormText.RU);
                setHeaderAndButton(TitleAndSubmit.RU)
                break;
        }
    },[language])

    const renderName=()=>{
   
                return ( 
                <>
                <label>
                {formText.name}
                <input type="text" value={name} onChange={handleNameChange} />
            </label>
            <label>
            {formText.email}
            <input type="email" value={email} onChange={handleEmailChange} />
        </label>
        <label>
            {formText.phoneNumber}
            <input type="text" value={phoneNumber} onChange={handlePhoneNumberChange} />
        </label>
        <label>
            {formText.companyName}
            <input type="text" value={companyName} onChange={handleCompanyNameChange} />
        </label>
        <label>
            {formText.inquiryDetails}
            <textarea type="text" value={inquiryDetails} onChange={handleInquiryDetailsChange} />
        </label>
        </>
            )
 
        }




    const visibilityClass = visible ? 'visible' : '';
    return(
        <div ref={ref} className={`IntakeFormContainer ${visibilityClass}`}>
 
 
     <form onSubmit={handleSubmit}>

        <div className='FormTitle'>
            {headerAndButton[0]}
        </div>
           {renderName()}
            <br />
   
            <br />
            <button type="submit">{headerAndButton[1]}</button>
        </form>
        
        </div>
    )
}
)
export default IntakeForm;