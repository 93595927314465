import React, { useState, useEffect } from 'react';
import '../Stylesheets/TypeEffect.css'
import { useLanguage } from './LanguageContext';
export default function TypeEffect() {
    const englishtexts = ["Design", "Develop", "Deploy"];
    const frenchtexts = ["Conception", "Développement", "Déploiement"];
    const spanishtexts = ["Diseño", "Despliegue", "Desarrollo"];
    const russiantexts = ["Дизайн ", "Разработка ", "Развертывание "];
    const { language, switchLanguage } = useLanguage();
    const [count, setCount] = useState(0);
    const [index, setIndex] = useState(0);
    const [letter, setLetter] = useState('');
    const [isPausing, setIsPausing] = useState(false);
    const [currentText, setCurrentText] = useState(['Design']);

    useEffect(() => {
        switch (language) {
            case 'EN':
                setCurrentText(englishtexts[count]);
                break;
            case 'FR':
                setCurrentText(frenchtexts[count]);
                break;
            case 'ES':
                setCurrentText(spanishtexts[count]);
                break;
            case 'RU':
                setCurrentText(russiantexts[count]);
                break;
            default:
                setCurrentText("Lets work Together"); // Set a default text
                break;
        }
    }, [language, count]); // Add 'count' as a dependency

    useEffect(() => {
        if (isPausing) {
            const pauseTimer = setTimeout(() => {
                setIsPausing(false);
                setIndex(index + 1);
            }, 1000); // 1-2 second pause
            return () => clearTimeout(pauseTimer);
        }

        const type = () => {
            if (count >= englishtexts.length) {
                setCount(0);
                return;
            }

            
                
            if (index <= currentText.length) {
                console.log(currentText)
                // Typing phase
                setLetter(currentText.slice(0, index));
                setIndex(index + 1);
            } else if (index > currentText.length && index <= currentText.length * 2) {
                // Deleting phase
                setLetter(currentText.slice(0, currentText.length * 2 - index));
                setIndex(index + 1);
            }

            if (index === currentText.length) {
                // Start pause after typing phase
                setIsPausing(true);
            } else if (index === currentText.length * 2) {
                // Reset for the next word
                setCount((prevCount) => (prevCount + 1) % englishtexts.length);
                setIndex(0);
            }
        };

        if (!isPausing) {
            const timerId = setTimeout(type, 120);
            return () => clearTimeout(timerId);
        }
    }, [index, count, isPausing]);

    return (
       <div className='TypeEffect'>
        {letter}

       </div>
 
    );
}
