import React from 'react';
import '../Stylesheets/Hero.css';
import covervid from '../Videos/stock.mp4';
import logo from '../Images/inverted_log.png'

export default function Hero() {
    return (
        <div className="hero-container" id="hero">
            <video autoPlay loop muted>
                <source src={covervid} type="video/mp4" />
                Your browser does not support the video tag.
            </video>
            <img src={logo} alt="Logo" className="logo" />
        </div>
    );
};
