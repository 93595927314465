import '../Stylesheets/ImageTwo.css'
import { useState, useEffect } from 'react'
import { Parallax } from 'react-parallax'
import Image from '../Images/unsplash.jpg'
import '../Stylesheets/ImageOne.css'
import TypeEffect from './TypeEffect'
 

export default function ImageTwo() {
const [windowWidth, setWindowWidth] = useState(window.innerWidth);
 
const backgroundImage = Image;
return (
 
        <Parallax className='image' bgImage={backgroundImage} strength={500}>
          <div className='content'>
          <div className='TypeEffect'>
          <TypeEffect />
            </div>
             </div>
        </Parallax>
    );

}