import { useState, useEffect } from 'react';
import '../Stylesheets/Language.css';
import { useLanguage } from './LanguageContext';
import 'flag-icons/css/flag-icons.min.css'; // Import the CSS for flag-icons
 
 

export default function Language() {
    const [dropdown, setDropdown] = useState(false);
    const { language, switchLanguage } = useLanguage();
    const [displayLanguage, setDisplayLanguage] = useState('English');
    const [isDisplayLanguageVisible, setIsDisplayLanguageVisible] = useState(false);
    const [flag, setFlag] = useState( <span className="fi fi-us" ></span>)


    useEffect(() => {
        let timeoutId;

        // Hide the display language initially
        setIsDisplayLanguageVisible(false);

        switch (language) {
            case 'EN':
                setDisplayLanguage('English');
                setFlag( <span className="fi fi-us" ></span>)
                break;
            case 'FR':
                setDisplayLanguage('Français');
                setFlag( <span className="fi fi-fr" ></span>)
                break;
            case 'ES':
                setDisplayLanguage('Español');
                setFlag( <span className="fi fi-es" ></span>)
                break;
            case 'RU':
                setDisplayLanguage('Русский');
                setFlag( <span className="fi fi-ru" ></span>)
                break;
            default:
                setDisplayLanguage('English');
        }

        // Set a timeout to show the display language after a delay
        timeoutId = setTimeout(() => {
            setIsDisplayLanguageVisible(true);
        }, 500); // Delay of 1 second

        return () => clearTimeout(timeoutId);
    }, [language]);

    const toggleDropdown = () => {
        setDropdown(!dropdown);
    };

    const handleLanguageChange = (lang) => () => {
        switchLanguage(lang);
        setDropdown(false);
    };

    return (
        <div onClick={toggleDropdown} className="LanguageContainer">
            {dropdown ? (
            
            <div className='displayLanguageWrapper'>
                <div className='Dropdown visible'>
                    <ul>
                        <li onClick={handleLanguageChange('EN')}>  <span className="fi fi-us" ></span><p>English</p></li>
                        <li onClick={handleLanguageChange('FR')}> <span className="fi fi-fr " ></span><p>Français</p></li>
                        <li onClick={handleLanguageChange('ES')}> <span className="fi fi-es" ></span><p>Español</p></li>
                        <li onClick={handleLanguageChange('RU')}> <span className="fi fi-ru" ></span><p>Русский</p></li>
                    </ul>
                </div>
                </div>
            ) : (<>
                <div className='Dropdown'>
                    <ul>
                        <li onClick={handleLanguageChange('EN')}><p>English</p></li>
                        <li onClick={handleLanguageChange('FR')}><p>Français</p></li>
                        <li onClick={handleLanguageChange('ES')}><p>Español</p></li>
                        <li onClick={handleLanguageChange('RU')}><p>Русский</p></li>
                    </ul>
                </div>
 
                        <div className='displayLanguage vis'>
                            {flag}
                            <p>{displayLanguage}</p>
                            <div className='downArrow'></div>
                        </div>
                   
                    </>
            )}
     
        </div>
    );
}
